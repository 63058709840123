<template>
    <div id="app">
        <AuditHitList />
    </div>
</template>

<script>
 import AuditHitList from '@/components/Mapping/AuditHitsPerProject';

 export default {
     components: {
         AuditHitList,

     }
 }
</script>
